import { toast } from 'react-toastify';
import {
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  SEARCH_CLIENT_LIST_LOADING,
  SEARCH_CLIENT_LIST_SUCCESS,
  SEARCH_CLIENT_LIST_FAILURE,
  GET_CLIENT_CONTACTS_FAILURE,
  GET_CLIENT_CONTACTS_LOADING,
  GET_CLIENT_CONTACTS_SUCCESS,
  SEARCH_CLIENT_USER_LIST_LOADING,
  SEARCH_CLIENT_USER_LIST_SUCCESS,
  SEARCH_CLIENT_USER_LIST_FAILURE,
  SET_SELECTED_CONTACT,
  SET_SELECTED_USER,
  ADD_CLIENT_USER_FAILURE,
  ADD_CLIENT_USER_LOADING,
  ADD_CLIENT_USER_SUCCESS,
  UPDATE_CLIENT_USER_FAILURE,
  UPDATE_CLIENT_USER_LOADING,
  UPDATE_CLIENT_USER_SUCCESS,
  SET_CLIENT_USER_FORM_EDITABLE,
  POST_CLIENT_LOGO_UPLOAD_LOADING,
  POST_CLIENT_LOGO_UPLOAD_SUCCESS,
  POST_CLIENT_LOGO_UPLOAD_FAILURE,
  POST_CLIENT_LOGO_LOADING,
  POST_CLIENT_LOGO_SUCCESS,
  POST_CLIENT_LOGO_FAILURE,
  SEARCH_CLIENT_CONTRACT_LIST_LOADING,
  SEARCH_CLIENT_CONTRACT_LIST_FAILURE,
  SEARCH_CLIENT_CONTRACT_LIST_SUCCESS,
  ADD_CLIENT_CONTRACT_SUCCESS,
  ADD_CLIENT_CONTRACT_FAILURE,
  ADD_CLIENT_CONTRACT_LOADING,
  ADD_CLIENT_INFO_LOADING,
  ADD_CLIENT_INFO_SUCCESS,
  ADD_CLIENT_INFO_FAILURE,
  DELETE_CLIENT_USER_LOADING,
  DELETE_CLIENT_USER_SUCCESS,
  DELETE_CLIENT_USER_FAILURE,
  SEARCH_CONTACT_LIST_LOADING,
  SEARCH_CONTACT_LIST_FAILURE,
  SEARCH_CONTACT_LIST_SUCCESS,
  SET_SELECTED_INDEX,
  POST_RESEND_INVITE_CLIENT_USER_FAILURE,
  POST_RESEND_INVITE_CLIENT_USER_LOADING,
  POST_RESEND_INVITE_CLIENT_USER_SUCCESS,
} from 'actions/client';
import {
  DELETED_USER_SUCCESSFULLY_MESSAGE,
  ERROR_MESSAGE,
  FILE_UPLOADED_SUCCESSFULLY_MESSAGE,
  RESEND_INVITE_SUCCESSFULLY,
  SAVE_SUCCESSFULLY_MESSAGE,
  UPDATED_SUCCESSFULLY_MESSAGE,
} from 'components/Common/AlertMessages';
import { DEFAULT_ATTEMPT_EMAIL_DURATION, NO, YES } from 'pages/ClientProfile/constants';
import { isArray, isBoolean, isEmpty, isNil } from 'lodash';
import { RISK_ADVISOR } from 'pages/ClientProfile/ContractConfiguration/constants';

const initialState = {
  loading: false,
  error: '',
  client: {
    ClientOrgName: '',
    ClientOrgId: '',
    DomainName: '',
    PhoneCode: '',
    MainPhone: '',
    PhoneExtension: '',
    YearFounded: '',
    NumberOfEmployeesRangeId: '',
    RegisteredBusinessAddressLine1: '',
    RegisteredBusinessAddressLine2: '',
    RegisteredBusinessAddressCity: '',
    RegisteredBusinessAddressCountry: '',
    RegisteredBusinessAddressState: '',
    RegisteredBusinessAddressZip: '',
  },
  list: [],
  contacts: [],
  userList: [],
  selectedContact: {},
  selectedUser: {},
  isUserFormEditable: false,
  clientContract: {},
  contactDetails: {},
  clientLogoUrl: '',
  clientAdded: false,
  userToBeSelected: null,
  selectedIndex: null,
};

export default function (state = initialState, action) {
  const { type, payload, isEditable } = action;

  switch (type) {
    case GET_CLIENT_REQUEST:
    case SEARCH_CLIENT_LIST_LOADING:
    case POST_CLIENT_LOGO_UPLOAD_LOADING:
    case POST_CLIENT_LOGO_LOADING:
    case DELETE_CLIENT_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        client: payload?.Data?.Rows[0],
        clientAdded: false,
      };
    }

    case POST_CLIENT_LOGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientLogoUrl: payload?.Data?.Url,
      };
    }
    case POST_CLIENT_LOGO_FAILURE: {
      return {
        ...state,
        loading: false,
        clientLogoUrl: payload,
      };
    }

    case GET_CLIENT_FAIL: {
      toast.error(payload.message);
      return {
        ...state,
        loading: false,
        clientAdded: false,
      };
    }

    case SEARCH_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
        clientAdded: false,
      };
    }

    case SEARCH_CLIENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload?.Data?.Rows,
        clientAdded: false,
      };
    }

    case SEARCH_CLIENT_USER_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_CLIENT_USER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        userList: [],
      };
    }

    case SEARCH_CLIENT_USER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        userList: payload?.Data?.Rows,
      };
    }

    case GET_CLIENT_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CLIENT_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        contacts: [],
      };
    }

    case GET_CLIENT_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        contacts: payload?.Data?.Rows,
      };
    }

    case SET_SELECTED_CONTACT: {
      return {
        ...state,
        selectedContact: payload || {},
      };
    }

    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: payload || {},
      };
    }

    case SET_SELECTED_INDEX: {
      return {
        ...state,
        selectedIndex: payload,
      };
    }

    case ADD_CLIENT_USER_LOADING:
    case SEARCH_CLIENT_CONTRACT_LIST_LOADING:
    case SEARCH_CONTACT_LIST_LOADING:
    case ADD_CLIENT_CONTRACT_LOADING:
    case UPDATE_CLIENT_USER_LOADING:
    case ADD_CLIENT_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_CLIENT_USER_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        isUserFormEditable: false,
        userToBeSelected: payload?.Data?.UserId,
      };
    }

    case ADD_CLIENT_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_CLIENT_USER_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        isUserFormEditable: false,
        userToBeSelected: null,
      };
    }

    case UPDATE_CLIENT_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_CLIENT_USER_FORM_EDITABLE: {
      return {
        ...state,
        isUserFormEditable: isEditable,
      };
    }

    case SEARCH_CLIENT_CONTRACT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        clientContract: {},
      };
    }

    case SEARCH_CLIENT_CONTRACT_LIST_SUCCESS: {
      let tempData = {};
      tempData = {
        ...payload?.Data,
        IsTPIRSubscribed: payload?.Data?.IsTPIRSubscribed ? YES : NO,
        IsPreAssessmentAllowed: payload?.Data?.IsPreAssessmentAllowed ? YES : NO,
        IsCORLCleared: payload?.Data?.IsCORLCleared ? YES : NO,
        IsRemediationEnabled: payload?.Data?.IsRemediationEnabled ? YES : NO,
        ReviewCorlRemRecommendationId: payload?.Data?.ReviewCorlRemRecommendationId ? YES : NO,
        AffordanceForAlternateRemGuidanceId: payload?.Data?.AffordanceForAlternateRemGuidanceId ? YES : NO,
        GRCApiIntegrationId: payload?.Data?.GRCApiIntegrationId ? YES : NO,
        RemediationPreferences: !isEmpty(payload?.Data?.RemediationPreferences) ? payload?.Data?.RemediationPreferences.split(',') : '',
        IsEnableEmailReminder: !isNil(payload?.Data?.IsEnableEmailReminder) ? payload?.Data?.IsEnableEmailReminder : 0,
        OutreachAttemptEmailDuration1: !isNil(payload?.Data?.OutreachAttemptEmailDuration1) ? payload?.Data?.OutreachAttemptEmailDuration1 : DEFAULT_ATTEMPT_EMAIL_DURATION,
        OutreachAttemptEmailDuration2: !isNil(payload?.Data?.OutreachAttemptEmailDuration2) ? payload?.Data?.OutreachAttemptEmailDuration2 : DEFAULT_ATTEMPT_EMAIL_DURATION,
        OutreachAttemptEmailDuration3: !isNil(payload?.Data?.OutreachAttemptEmailDuration3) ? payload?.Data?.OutreachAttemptEmailDuration3 : DEFAULT_ATTEMPT_EMAIL_DURATION,
      };
      Object.entries(tempData).forEach((item) => {
        const [key, value] = item;
        tempData[`${key}`] = (isNil(value) || isArray(value)) ? value : value.toString();
      });
      return {
        ...state,
        loading: false,
        clientContract: tempData,
      };
    }

    case ADD_CLIENT_CONTRACT_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      let tempFormData = {
        ...payload?.Data,
        IsTPIRSubscribed: payload?.Data?.IsTPIRSubscribed ? YES : NO,
        IsPreAssessmentAllowed: payload?.Data?.IsPreAssessmentAllowed ? YES : NO,
        IsCORLCleared: payload?.Data?.IsCORLCleared ? YES : NO,
        IsRemediationEnabled: payload?.Data?.IsRemediationEnabled ? YES : NO,
        ReviewCorlRemRecommendationId: payload?.Data?.ReviewCorlRemRecommendationId ? YES : NO,
        AffordanceForAlternateRemGuidanceId: payload?.Data?.AffordanceForAlternateRemGuidanceId ? YES : NO,
        GRCApiIntegrationId: payload?.Data?.GRCApiIntegrationId ? YES : NO,
        RemediationPreferences: !isEmpty(payload?.Data?.RemediationPreferences) ? payload?.Data?.RemediationPreferences.split(',') : '',
      };
      Object.entries(tempFormData).forEach((item) => {
        const [key, value] = item;
        tempFormData[`${key}`] = (isNil(value) || isArray(value) || isBoolean(value)) ? value : value.toString();
      });
      return {
        ...state,
        loading: false,
        clientContract: tempFormData,
      };
    }

    case ADD_CLIENT_CONTRACT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_CLIENT_INFO_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        clientAdded: true,
      };
    }

    case ADD_CLIENT_INFO_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        clientAdded: false,
      };
    }

    case DELETE_CLIENT_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_CLIENT_USER_SUCCESS: {
      toast.success(DELETED_USER_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_CONTACT_LIST_FAILURE: {
      const { contactType } = action
      return {
        ...state,
        loading: false,
        contactDetails: contactType ? { ContactType: contactType } : {},
      };
    }

    case SEARCH_CONTACT_LIST_SUCCESS: {
      const { contactType } = action
      return {
        ...state,
        loading: false,
        contactDetails: {...payload?.Data?.Rows[0], ContactType: contactType},        
      };
    }

    case POST_RESEND_INVITE_CLIENT_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_RESEND_INVITE_CLIENT_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_RESEND_INVITE_CLIENT_USER_SUCCESS: {
      toast.success(RESEND_INVITE_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
