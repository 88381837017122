import { faCircleInfo, faAddressBook, faUsers, faFileContract, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const COMPANY_INFORMATION_MENU = 'information';
export const CONTACTS_MENU = 'contacts';
export const CLIENT_USERS_MENU = 'users';
export const CONTRACT_CONFIGURATION_MENU = 'contractConfiguration';
export const CLIENT_EMAIL_TEMPLATES = 'Email Templates';

export const CLIENT_PROFILE_SIDEBAR_MENUS = [
  { label: 'COMPANY INFO', value: COMPANY_INFORMATION_MENU, faIcon: faCircleInfo },
  { label: 'CONTACTS', value: CONTACTS_MENU, faIcon: faAddressBook },
  { label: 'USERS', value: CLIENT_USERS_MENU, faIcon: faUsers },
  { label: 'CONTRACT CONFIGURATION', value: CONTRACT_CONFIGURATION_MENU, faIcon: faFileContract },
  { label: 'EMAIL TEMPLATES', value: CLIENT_EMAIL_TEMPLATES, faIcon: faEnvelope },
];
export const YES = 'Yes';
export const NO = 'No';
export const ENABLE = 2;
export const DISABLE = 1;
export const DEFAULT_ATTEMPT_EMAIL_DURATION = "5";

export const CONTRACT_CONFIGURATION_INITIAL_VALUES = {
  IsTPIRSubscribed: 'No',
  IsCORLCleared: 'No',
  GRCApiIntegrationId: 'No',
  TPIRVendorLimit: 0,
  TPIROutreachLimit: 0,
  AssessmentLaneLimit: 0,
  ReservedLaneLimit: 0,
  ExpeditedAssessmentLimit: 0,
  RiskAdvisorEmail: null,
  OutreachAttemptEmailDuration1: DEFAULT_ATTEMPT_EMAIL_DURATION,
  OutreachAttemptEmailDuration2: DEFAULT_ATTEMPT_EMAIL_DURATION,
  OutreachAttemptEmailDuration3: DEFAULT_ATTEMPT_EMAIL_DURATION,
  IsEnableEmailReminder: false,
  CCAssessmentRequester1: 1,
  CCAssessmentRequester2: 1,
  CCAssessmentRequester3: 1,
};

export const MINI_RECORD_TO_SEARCH = 5;
