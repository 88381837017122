import { isNil } from "lodash";

export const getAppLookupBody = (lookupData) => {
  const fields = [];

  lookupData.forEach((field) => {
    fields.push(field);
  });

  return {
    intent: '',
    fields: fields,
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getUserDomainRuleLookupBody = () => {
  return {
    intent: '',
    fields: ['UserDomainRuleType'],
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getDescriptionValueLabelSearchBody = (lookupEntityId = null) => {
  const criterion = [];
  if (!isNil(lookupEntityId)) {
    criterion.push({
      "Field": "LookupEntityId",
      "Operator": "=",
      "Value": lookupEntityId
    })
  }
  return {
    "Intent": "",
    "Fields": [
      "LookupEntityId",
      "LookupEntityName",
      "LookupEntityValue",
      "LookupEntityLabel",
      "ShortDescription",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getAssessmentWorkflowConfigLookupBody = () => {
  return {
    intent: '',
    fields: ['VendorResponseAction'],
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getManagementDashboardLookupBody = () => {
  return {
    intent: '',
    fields: ['ManagementDashboard'],
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getProgramMetricsLookupBody = () => {
  return {
    intent: '',
    fields: ['ProgramMetrics'],
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};