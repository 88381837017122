export const getVendorProfileContactsBody = (vendorOrgId) => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'ContactId', 'IsTeamContact', 'FullName', 'VendorOrgId'],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: '=',
        value: vendorOrgId,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchVendorProfileSpecificContactsBody = (searchValue, vendorOrgId) => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'ContactType', 'CellPhone', 'OfficePhone', 'ContactId', 'IsTeamContact', 'FullName', 'VendorOrgId'],
    criterion: [
      {
        field: 'FullName',
        operator: 'startswith',
        value: searchValue,
      },
      {
        field: 'VendorOrgId',
        operator: '=',
        value: vendorOrgId,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
