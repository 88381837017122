export const IS_TPIR_NOT_SUBSCRIBED = 'No';
export const IS_NOT_CORL_CLEARED = 'No';
export const IS_CORL_CLEARED = 'Yes';
export const IS_REMEDIATION_ENABLED = 'No';

export const FORM_INITALIZED = 1;
export const EXECUTIVE_SUMMARY_DISABLED = 1;
export const STAKEHOLDER_PORTAL_DISABLED = 1;
export const DYNAMIC_VENDOR_ROASTER_DISABLED = 1;
export const VENDOR_COLLABORATION_SCORE_DISABLED = 1;
export const CLIENT_VENDOR_INVENTORY_DISABLED = 1;
export const IS_REMEDIATION_ENABLED_YES = 1;
export const IS_REMEDIATION_ENABLED_NO = 0;
export const REVIEW_CORL_REMEDIATION_RECOMMENDATION_YES = 1;
export const REVIEW_CORL_REMEDIATION_RECOMMENDATION_NO = 0;
export const AFFORDANCE_FOR_ALTERNATE_GUIDANCE_YES = 1;
export const AFFORDANCE_FOR_ALTERNATE_GUIDANCE_NO = 0;
export const VALIDATIONS_PREFERENCES_VALIDATED_ONLY = 1;
export const GRC_API_INTEGRATION_YES = 1;
export const GRC_API_INTEGRATION_NO = 0;
export const PORTAL_ASSESSMENT_ROUTING_DISABLED = 1;
export const PORTAL_ASSESSMENT_ROUTING_ENABLED = 2;

export const CORL = 'CORL';
export const PRIMARY = 'Primary';
export const SECONDARY = 'Secondary';
export const ERROR_MESSAGE_FOR_DOMAIN_VALIDATTION = 'Invalid domain name';

export const LANE_TYPE_SUBCOUNT = 2;
export const LANE_TYPE_IS_LANE = 1;