//this decides idle timeout for application
export const IDLE_TIMER_TIMEOUT = 120 * 60 * 1000; //milliseconds
export const IDLE_TIMER_THROTTLE = 500;
//Setting below decides amount time in milliseconds. Other tabs are notified about the timeout on active tab.
export const IDLE_TIMER_SYNC_TIMERS = 200;

export const IDLE_TIMEOUT_MESSAGE = 'For security reasons, you have been logged out due to inactivity.';

export const NO_ROLES_ASSIGNED_MESSAGE = 'No roles are assigned to the user.';

export const LOGOUT_MESSAGE = 'Are you sure you want to logout?';

export const YES = 'Yes';
export const NO = 'No';
export const OK = 'Ok';

export const APP_CONFIG_KEYS = {
  ASSESSMENTS_OVERVIEW_EVIDENCE_SUFFIX: 'ASSESSMENTS_OVERVIEW_EVIDENCE_SUFFIX',
  ASSESSMENTS_OVERVIEW_RESULT_SUFFIX: 'ASSESSMENTS_OVERVIEW_RESULT_SUFFIX',
  ASSESSMENT_REQUEST_MAX_FILE_SIZE: 'ASSESSMENT_REQUEST_MAX_FILE_SIZE',
  CORL_EMAIL_VSRM: 'CORL_EMAIL_VSRM',
  ASSESSMENT_REQUEST_MAX_FILES_TO_UPLOAD: 'ASSESSMENT_REQUEST_MAX_FILES_TO_UPLOAD',
  DISPLAY_ZENDESK_STARTED_GUIDE: 'DISPLAY_ZENDESK_STARTED_GUIDE',
  DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS: 'DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS',
  DISPLAY_ZENDESK_SUPPORT_ARTICLES: 'DISPLAY_ZENDESK_SUPPORT_ARTICLES',
  EVIDENCE_DOCUMENT_MAX_FILE_SIZE: 'EVIDENCE_DOCUMENT_MAX_FILE_SIZE',
  EVIDENCE_DOCUMENT_MAX_FILES_TO_UPLOAD: 'EVIDENCE_DOCUMENT_MAX_FILES_TO_UPLOAD',
  CUSTOMER_VENDOR_LIST_MAX_ROWS: 'CUSTOMER_VENDOR_LIST_MAX_ROWS'
};

//Pagination Constants
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_ROWS_PER_PAGE = 25;
export const PAGE_ZERO = 0;
export const PAGE_SIZE_TEN = 10;

//The time window before which token will be updated (in seconds)
export const TOKEN_UPDATE_TIME_WINDOW = 900;

//The time interval to check for the token expiry (in miliseconds)
export const TOKEN_CHECK_TIME_INTERVAL = 300 * 1000;

export const QUEUED = 1;
export const EXPEDITED = 2;
export const ACTIVE = 3;
export const RESERVED = 4;
export const REMEDIATION = 5;
export const COMPLETED = 6;
export const REMEDIATION_STAGE = 'Remediation';
export const ACTIVE_STAGE = 'Active';
export const COMPLETED_STAGE = 'Completed';

export const ASSESSMENT_LIST_STEPS = [
  {
    AssessmentStageId: 1,
    AssessmentStageName: ACTIVE_STAGE,
  },
  {
    AssessmentStageId: 2,
    AssessmentStageName: REMEDIATION_STAGE,
  },
  {
    AssessmentStageId: 3,
    AssessmentStageName: COMPLETED_STAGE,
  },
];

export const TRUE = '1';
export const FALSE = '2';

export const VENDOR_ENTITY = 1; //This corresponds to the enum in Confirm and Merge Api model.

export const YES_VALUE = 1;
export const NO_VALUE = 0;

export const ADDED_SUCCESSFULLY = 'Added Successfully';
export const UPDATED_SUCCESSFULLY = 'Updated Successfully';
export const PUBLISHED_SUCCESSFULLY = 'Published Successfully';


//Legacy and protal 2.0 constants
export const DYNAMIC = 'Dynamic';
export const PORTAL_2 = 'Portal 2.0';

export const ASSESSMENT_TERMINATED = 1;
export const ASSESSMENT_PAUSED =4;

export const COPILOT_LABEL = 'Companion';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';