import axios from 'axios';
import { setErrors } from './errorHandler';
import { getAssessmentRequestReviewCommentsbody, getAssessmentRequestSearchBody } from 'utils/assessmentRequestsUtils';
import { getErrorObject } from 'utils/apiUtils';
import {
  convertToValueLable,
  getAssesmmentRequestProductListSearchBody,
  getAssessmentConfigurationBody,
  getAssessmentListBody,
  getAssessmentListCountBody,
  getAssessmentQueueSearchBody,
  getClientListDataSearchBody,
  getCustomerVendorProductListSearchBody,
  getQuestionnaireTemplateSearchBody,
  getVendorListDataSearchBody,
} from 'utils/assessmentRequests';
import { ASSESSMENT_REQUEST_LIST_TAB_OPTIONS } from 'pages/AssessmentRequest/AssessmentRequestList/assessmentRequestListConstants';
import { cloneDeep, isEmpty, isNil, uniqBy } from 'lodash';
import { SEARCH_COMMENTS_FAILURE, SEARCH_COMMENTS_LOADING, SEARCH_COMMENTS_SUCCESS, setComments } from './comments';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/constants';
import { ZERO } from 'constants/numbers';
import {
  ASSESSMENT_REQUEST_CANCELLED,
  ASSESSMENT_REQUEST_IN_REVIEW,
  ASSESSMENT_REQUEST_NEW,
  ASSESSMENT_REQUEST_SUBMITTED,
} from 'pages/AssessmentRequest/AssessmentRequestReview/constants';
import { SECURITY_QUESTIONNAIRE_TYPE } from 'pages/QuestionnaireBuilder/constants';

export const SEARCH_ASSESSMENT_REQUEST_LOADING = '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_LOADING';
export const SEARCH_ASSESSMENT_REQUEST_SUCCESS = '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_SUCCESS';
export const SEARCH_ASSESSMENT_REQUEST_FAILURE = '@@assessmentRequest/SEARCH_ASSESSMENT_REQUEST_FAILURE';

export const searchAssessmentRequest = (id) => async (dispatch) => {
  dispatch({
    type: SEARCH_ASSESSMENT_REQUEST_LOADING,
  });
  const body = getAssessmentRequestSearchBody(id);
  try {
    const res = await axios.post(`/serviceprovider/assessmentrequest/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_ASSESSMENT_REQUEST_FAILURE,
        payload: {},
      });
    } else {
      if (res?.data?.Data?.Rows?.length > 0) {
        const assessmentRequest = res?.data?.Data?.Rows[0];
        if (!isNil(assessmentRequest?.ProductVendorId) && assessmentRequest?.ProductVendorId !== 0) {
          const productBody = getCustomerVendorProductListSearchBody(assessmentRequest?.VendorOrgId, '', assessmentRequest?.ProductVendorId);

          const productRes = await axios.post(`/customer/vendor/product/fulltext/search`, productBody);
          if (productRes?.data?.Data.Rows?.length > 0) {
            dispatch(
              getCustomerVendorProductList(assessmentRequest?.VendorOrgId, '', () => {
                dispatch({
                  type: SEARCH_ASSESSMENT_REQUEST_SUCCESS,
                  payload: assessmentRequest,
                  product: productRes?.data?.Data.Rows[0],
                });
              })
            );
          }
        } else {
          dispatch(
            getCustomerVendorProductList(assessmentRequest?.VendorOrgId, '', () => {
              dispatch({
                type: SEARCH_ASSESSMENT_REQUEST_SUCCESS,
                payload: assessmentRequest,
                product: {},
              });
            })
          );
        }
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_REQUEST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const PUT_ASSESSMENT_REQUEST_LOADING = '@@assessmentRequest/PUT_ASSESSMENT_REQUEST_LOADING';
export const PUT_ASSESSMENT_REQUEST_SUCCESS = '@@assessmentRequest/PUT_ASSESSMENT_REQUEST_SUCCESS';
export const PUT_ASSESSMENT_REQUEST_FAILURE = '@@assessmentRequest/PUT_ASSESSMENT_REQUEST_FAILURE';

export const updateAssessmentRequest = (formData, id, responseQuestions, statusId, selectedSecurityQuestionnnaireList, callback) => async (dispatch) => {
  dispatch({
    type: PUT_ASSESSMENT_REQUEST_LOADING,
  });

  let newDescriptionList = [];
  let newFileTypeList = [];
  let uploadedFileList = [];
  let tempFileData = formData?.AssessmentRequestUploadedFileList.filter((item) => {
    if (!item?.AssessmentRequestUploadedFileId) return item;
  });

  tempFileData.forEach((assessmentFile, index) => {
    if (!isEmpty(assessmentFile?.File?.name)) {
      newDescriptionList.push(assessmentFile?.Description);
      newFileTypeList.push(assessmentFile?.FileType);
      uploadedFileList.push(assessmentFile?.File);
    }
  });

  let setStatusFormData = {
    AssessmentRequestId: formData?.AssessmentRequestId,
    AssessmentStatus: statusId,
    ReviewComment: formData?.ReviewComment,
  };

  const existingUploadedFileList = formData?.AssessmentRequestUploadedFileList.filter((item) => {
    if (item.AssessmentRequestUploadedFileId) return item;
  });

  let tempFormData = {
    ...formData,
    AssessmentRequestQuestionResponseList: responseQuestions,
    SecurityQuestionnaireTemplateList: selectedSecurityQuestionnnaireList,
    UploadedFileDescriptionList: newDescriptionList,
    UploadedFileTypeList: newFileTypeList,
    BusinessDomain: formData?.BusinessDomain ? formData?.BusinessDomain?.toString() : null,
    BusinessCapabilityGroup: formData?.BusinessCapabilityGroup ? formData?.BusinessCapabilityGroup?.toString() : null,
    IsProductMedicalDevice: isEmpty(formData?.IsProductMedicalDevice) ? ZERO : formData?.IsProductMedicalDevice,
    AssessmentStatusId: statusId ? statusId : formData?.AssessmentStatusId,
    AssessmentRequestUploadedFileList: existingUploadedFileList.map((item) => {
      return {
        AssessmentRequestUploadedFileId: item?.AssessmentRequestUploadedFileId,
        Description: item?.Description,
        FileName: item?.FileName,
        FileType: item?.FileType,
        DownloadClientFileUrl: {
          Url: item?.DownloadUrl,
        },
      };
    }),
  };

  let body = new FormData();
  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  uploadedFileList.forEach((item, index) => {
    body.append('UploadedFileList', item);
  });

  try {
    const res = await axios.put(`/serviceprovider/assessmentrequest/${id}`, body, {
      timeout: 180000, // 3 minutes
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: PUT_ASSESSMENT_REQUEST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: PUT_ASSESSMENT_REQUEST_SUCCESS,
        payload: res?.data?.Data,
        statusId: statusId,
      });
      if (setStatusFormData?.AssessmentStatus !== ASSESSMENT_REQUEST_SUBMITTED) {
        dispatch(setAssessmentRequestStatus(setStatusFormData));
      }
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: PUT_ASSESSMENT_REQUEST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const searchAssessmentRequestReviewComments = (id) => async (dispatch) => {
  dispatch({
    type: SEARCH_COMMENTS_LOADING,
  });
  const body = getAssessmentRequestReviewCommentsbody(id);
  try {
    const res = await axios.post(`serviceprovider/assessmentrequest/${id}/reviewcomment/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_COMMENTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_COMMENTS_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      dispatch(setComments(res?.data?.Data?.Rows));
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COMMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ASSESSMENT_REQUEST_STATUS_LOADING = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_STATUS_LOADING';
export const SET_ASSESSMENT_REQUEST_STATUS_SUCCESS = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_STATUS_SUCCESS';
export const SET_ASSESSMENT_REQUEST_STATUS_FAILURE = '@@assessmentRequest/SET_ASSESSMENT_REQUEST_STATUS_FAILURE';

export const setAssessmentRequestStatus = (formData) => async (dispatch) => {
  dispatch({
    type: SET_ASSESSMENT_REQUEST_STATUS_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    const res = await axios.post(`/serviceprovider/assessmentrequest/${formData?.AssessmentRequestId}/action/updatestatus`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SET_ASSESSMENT_REQUEST_STATUS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SET_ASSESSMENT_REQUEST_STATUS_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SET_ASSESSMENT_REQUEST_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ASSESSMENT_REQUESTS_TAB_VALUE = '@@assessmentsRequests/SET_ASSESSMENT_REQUESTS_TAB_VALUE';

export const setAssessmentRequestsTabValue =
  (value = ASSESSMENT_REQUEST_LIST_TAB_OPTIONS.REQUESTS) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_REQUESTS_TAB_VALUE,
        payload: value,
      });
    };

export const SEARCH_ASSESSMENT_REQUEST_LIST_LOADING = '@@assessmentsRequests/SEARCH_ASSESSMENT_REQUEST_LIST_LOADING';
export const SEARCH_ASSESSMENT_REQUEST_LIST_SUCCESS = '@@assessmentsRequests/SEARCH_ASSESSMENT_REQUEST_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_REQUEST_LIST_FAILURE = '@@assessmentsRequests/SEARCH_ASSESSMENT_REQUEST_LIST_FAILURE';

export const getAssessmentRequestListData =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_REQUEST_LIST_LOADING });
      try {
        const body = getAssessmentQueueSearchBody(searchData);
        const response = await axios.post(`serviceprovider/assessmentrequest/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_REQUEST_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_REQUEST_LIST_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_REQUEST_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_LIST_LOADING = '@@assessmentsRequests/SEARCH_ASSESSMENT_LIST_LOADING';
export const SEARCH_ASSESSMENT_LIST_SUCCESS = '@@assessmentsRequests/SEARCH_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_FAILURE = '@@assessmentsRequests/SEARCH_ASSESSMENT_LIST_FAILURE';

export const getAssessmentListData =
  (formData = {}, page, rowPerPage, sortModel = []) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_LIST_LOADING });
      try {
        const searchData = {
          ClientOrgProductVendor: formData?.ClientProductVendor,
          ClientOrgId: formData?.ClientOrgId,
          VendorName: formData?.VendorOrgName,
          ProductName: formData?.ProductName,
          AssessmentTypeId: formData?.AssessmentRequestType,
          AssessmentDisplayStatus: formData?.AssessmentStatusId,
        };
        const body = getAssessmentListBody(searchData, page, rowPerPage, sortModel);
        const response = await axios.post(`serviceprovider/legacy/assessment/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_CLIENT_LIST_LOADING = '@@assessmentsRequests/SEARCH_CLIENT_LIST_LOADING';
export const SEARCH_CLIENT_LIST_SUCCESS = '@@assessmentsRequests/SEARCH_CLIENT_LIST_SUCCESS';
export const SEARCH_CLIENT_LIST_FAILURE = '@@assessmentsRequests/SEARCH_CLIENT_LIST_FAILURE';

export const getClientListData =
  (page = 1, searchValue = '', callback) =>
    async (dispatch) => {
      dispatch({
        type: SEARCH_CLIENT_LIST_LOADING,
      });
      const body = getClientListDataSearchBody(page, searchValue);
      try {
        const res = await axios.post(`/serviceprovider/client/search`, body);
        if (res?.data?.ErrorCount > 0) {
          const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_CLIENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_CLIENT_LIST_SUCCESS,
            payload: res?.data,
            searchClientValue: searchValue,
          });
          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: SEARCH_CLIENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_VENDOR_LIST_LOADING = '@@assessmentsRequests/SEARCH_VENDOR_LIST_LOADING';
export const SEARCH_VENDOR_LIST_SUCCESS = '@@assessmentsRequests/SEARCH_VENDOR_LIST_SUCCESS';
export const SEARCH_VENDOR_LIST_FAILURE = '@@assessmentsRequests/SEARCH_VENDOR_LIST_FAILURE';

export const getVendorListData =
  (page = 1, searchValue = '', callback) =>
    async (dispatch) => {
      dispatch({
        type: SEARCH_VENDOR_LIST_LOADING,
      });
      const body = getVendorListDataSearchBody(page, searchValue);
      try {
        const res = await axios.post(`serviceprovider/vendor/search`, body);
        if (res?.data?.ErrorCount > 0) {
          const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_VENDOR_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_VENDOR_LIST_SUCCESS,
            payload: res?.data,
            searchVendorValue: searchValue,
          });
          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: SEARCH_VENDOR_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_LOADING = '@@assessmentsRequests/SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_LOADING';
export const SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_SUCCESS = '@@assessmentsRequests/SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_FAILURE = '@@assessmentsRequests/SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_FAILURE';

export const getAssessmentRequestProductList =
  (page = 1, searchValue = '', callback) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_LOADING });
      try {
        const body = getAssesmmentRequestProductListSearchBody(page, searchValue);
        const response = await axios.post(`/serviceprovider/assessmentrequest/product/search`, body);
        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_SUCCESS,
            payload: response?.data || [],
            searchProductValue: searchValue,
          });
          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_CONFIG_LOADING = '@assessmentsRequests/SEARCH_ASSESSMENT_CONFIG_LOADING';
export const SEARCH_ASSESSMENT_CONFIG_SUCCESS = '@assessmentsRequests/SEARCH_ASSESSMENT_CONFIG_SUCCESS';
export const SEARCH_ASSESSMENT_CONFIG_FAILURE = '@assessmentsRequests/SEARCH_ASSESSMENT_CONFIG_FAILURE';

export const getAssessmentConfiguration = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_CONFIG_LOADING });
  try {
    const body = getAssessmentConfigurationBody();
    const response = await axios.post(`/legacy/assessment/configuration/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_LIST_COUNT_LOADING = '@@assessmentsRequests/SEARCH_ASSESSMENT_LIST_COUNT_LOADING';
export const SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS = '@@assessmentsRequests/SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_COUNT_FAILURE = '@@assessmentsRequests/SEARCH_ASSESSMENT_LIST_COUNT_FAILURE';

export const getAssessmentListCountData =
  (formData = {}, sortModel = []) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_LIST_COUNT_LOADING });
      try {
        const searchData = {
          ClientOrgProductVendor: formData?.ClientProductVendor,
          ClientOrgId: formData?.ClientOrgId,
          VendorName: formData?.VendorOrgName,
          ProductName: formData?.ProductName,
          AssessmentTypeId: formData?.AssessmentRequestType,
          AssessmentDisplayStatus: formData?.AssessmentStatusId,
        };
        const body = getAssessmentListCountBody(searchData, sortModel);
        const response = await axios.post(`serviceprovider/legacy/assessment/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
            payload: 0,
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_VENDOR_LIST_PAGE = '@@assessmentsRequests/SET_VENDOR_LIST_PAGE';

export const setVendorListPage =
  (page = 1, callback) =>
    async (dispatch) => {
      dispatch({
        type: SET_VENDOR_LIST_PAGE,
        payload: page,
      });
      if (callback) {
        callback();
      }
    };

export const UPDATE_VENDOR_LIST = '@@assessmentsRequests/UPDATE_VENDOR_LIST';

export const updateVendorList =
  (list = [], callback) =>
    async (dispatch) => {
      dispatch({
        type: UPDATE_VENDOR_LIST,
        payload: list,
      });
      if (callback) {
        callback();
      }
    };

export const SET_CLIENT_LIST_PAGE = '@@assessmentsRequests/SET_CLIENT_LIST_PAGE';

export const setClientListPage =
  (page = 1, callback) =>
    async (dispatch) => {
      dispatch({
        type: SET_CLIENT_LIST_PAGE,
        payload: page,
      });
      if (callback) {
        callback();
      }
    };

export const UPDATE_CLIENT_LIST = '@@assessmentsRequests/UPDATE_CLIENT_LIST';

export const updateClientList =
  (list = [], callback) =>
    async (dispatch) => {
      dispatch({
        type: UPDATE_CLIENT_LIST,
        payload: list,
      });
      if (callback) {
        callback();
      }
    };

export const SET_PRODUCT_LIST_PAGE = '@@assessmentsRequests/SET_PRODUCT_LIST_PAGE';

export const setProductListPage =
  (page = 1, callback) =>
    async (dispatch) => {
      dispatch({
        type: SET_PRODUCT_LIST_PAGE,
        payload: page,
      });
      if (callback) {
        callback();
      }
    };

export const UPDATE_PRODUCT_LIST = '@@assessmentsRequests/UPDATE_PRODUCT_LIST';

export const updateProductList =
  (list = [], callback) =>
    async (dispatch) => {
      dispatch({
        type: UPDATE_PRODUCT_LIST,
        payload: list,
      });
      if (callback) {
        callback();
      }
    };

export const GET_EXTERNAL_TICKET_URL_LOADING = '@@assessmentRequest/GET_EXTERNAL_TICKET_URL_LOADING';
export const GET_EXTERNAL_TICKET_URL_SUCCESS = '@@assessmentRequest/GET_EXTERNAL_TICKET_URL_SUCCESS';
export const GET_EXTERNAL_TICKET_URL_FAILURE = '@@assessmentRequest/GET_EXTERNAL_TICKET_URL_FAILURE';

export const getAssessmentRequestExternalTicketUrl = (id) => async (dispatch) => {
  dispatch({
    type: GET_EXTERNAL_TICKET_URL_LOADING,
  });
  try {
    const res = await axios.get(`/app/zendesk/ticket/${id}/action/geturl`);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_EXTERNAL_TICKET_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_EXTERNAL_TICKET_URL_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_EXTERNAL_TICKET_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ASSESSMENT_PAGE_NO = '@@assessmentsRequests/SET_ASSESSMENT_PAGE_NO';

export const setAssessmentPageNo =
  (page = 0) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_PAGE_NO,
        payload: page,
      });
    };

export const SET_ASSESSMENT_ROW_PER_PAGE = '@@assessmentsRequests/SET_ASSESSMENT_ROW_PER_PAGE';

export const setAssessmentRowPerPage =
  (page = DEFAULT_ROWS_PER_PAGE, callback) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_ROW_PER_PAGE,
        payload: page,
      });
      if (callback) {
        callback(page);
      }
    };

export const SET_ASSESSMENT_REQUESTS_FILTERS = '@@assessmentsRequests/SET_ASSESSMENT_REQUESTS_FILTERS';

export const setAssessmentRequestsFilters =
  (data = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_REQUESTS_FILTERS,
        payload: data,
      });
    };

export const SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING =
  '@@assessmentRequests/SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING';
export const SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS =
  '@@assessmentRequests/SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE =
  '@@assessmentRequests/SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE';

export const getCustomerVendorProductList =
  (vendorOrgId, searchText = '', callback) =>
    async (dispatch) => {
      dispatch({
        type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING,
      });

      const body = getCustomerVendorProductListSearchBody(vendorOrgId, searchText);
      try {
        const res = await axios.post(`/customer/vendor/product/fulltext/search`, body);
        if (res?.data?.ErrorCount > 0) {
          if (callback) {
            callback();
          }
          dispatch({
            type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS,
            payload: res?.data,
          });
          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST = '@@assessmentRequests/SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST';

export const setCustomerVendorProductList =
  (val = []) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST,
        payload: val,
      });
    };

export const SET_ASSESSMENT_REQUEST = '@@assessmentRequests/SET_ASSESSMENT_REQUEST';

export const setAssessmentRequest =
  (val = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_REQUEST,
        payload: val,
      });
    };

export const SEARCH_DEFAULT_QT_REQUEST = '@assessmentRequests/SEARCH_DEFAULT_QT_REQUEST';
export const SEARCH_DEFAULT_QT_SUCCESS = '@assessmentRequests/SEARCH_DEFAULT_QT_SUCCESS';
export const SEARCH_DEFAULT_QT_FAILIURE = '@assessmentRequests/SEARCH_DEFAULT_QT_FAILIURE';

export const searchQuestionnaireTemplates = (questionnaireType = null, searchValue = null, page = 0, callback = null) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SEARCH_DEFAULT_QT_REQUEST,
    });

    const state = getState()?.assessmentRequests;
    const body = getQuestionnaireTemplateSearchBody(questionnaireType, searchValue, page);

    try {
      const res = await axios.post('/questionnairetemplate/search', body);
      if (res?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);

        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_DEFAULT_QT_FAILIURE,
        });
      } else {
        const response = convertToValueLable(res?.data?.Data?.Rows);
      
        let payload;
        let list = state?.questionnaireTemplateList || [];

        if (!isNil(searchValue)) {
          payload = {
            questionnaireTemplateList: uniqBy([...list, ...response], 'value'),
          };
        } else {
          payload = {
            questionnaireTemplateList: uniqBy([...list, ...response], 'value'),
            pageNo: page,
          };
        }

        dispatch({
          type: SEARCH_DEFAULT_QT_SUCCESS,
          payload,
        });
        
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch({
        type: SEARCH_DEFAULT_QT_FAILIURE,
      });
    }
  };
};

export const SET_QUESTIONNAIRE_TEMPLATES = '@assessmentRequests/SET_QUESTIONNAIRE_TEMPLATES';

export const setQuestionnaireTemplates = () => async dispatch => {
   dispatch({
      type: SET_QUESTIONNAIRE_TEMPLATES,
   });
};