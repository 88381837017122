import { faCheck, faGlobe, faScrewdriverWrench, faShield } from "@fortawesome/free-solid-svg-icons";

export const VENDORS_PRODUCTS_TAB_OPTIONS = {
  PRODUCTS: 'PRODUCTS',
  PRODUCTGROUPS: 'PRODUCT GROUPS',
};

export const VENDORS_PRODUCTS_TAB_LIST = [
  {
    label: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
    value: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
  },
  {
    label: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTGROUPS,
    value: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTGROUPS,
  },
];

export const ERROR_MESSAGE = 'Something went wrong, Please try again';

export const PRODUCT_GROUP_ADDED_SUCCESSFULLY = 'Product Group Added Successfully';
export const PRODUCT_GROUP_UPDATED_SUCCESSFULLY = 'Product Group Updated Successfully';
export const PRODUCT_GROUP_DELETED_SUCCESSFULLY = 'Product Group Deleted Successfully';

export const NO_PRODUCTS_AVAILABLE = 'No Products Available';

export const FORCE_DELETE_PRODUCT_CONFIRMATION_MESSAGE = 'Are you sure you want to delete all associated data? Please confirm.';

export const FORCE_DELETE_PRODUCTGROUP_CONFIRMATION_MESSAGE = 'This product group has products associated with it. Are you sure to delete this product group?';

export const NO_PRODUCT_GROUP_ASSOCIATED = 0;

export const DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE = 'deassociate products from productgroup';

export const DELETE_PRODUCT_COMPONENT_CONFIRMATION = 'Are you sure you want to delete the component?';
export const ADD_COMPONENT = 'Add Component';
export const EDIT_COMPONENT = 'Edit Component';

export const ZERO_INTEGER = 0;

export const EVIDENCE_REQUIREMENT_TAB_OPTIONS = {
  APPLICABLE: 'APPLIES',
  NOT_APPLICABLE: 'NOT APPLICABLE',
};

export const EVIDENCE_REQUIREMENT_TAB_LIST = [
  {
    label: EVIDENCE_REQUIREMENT_TAB_OPTIONS.APPLICABLE,
    value: EVIDENCE_REQUIREMENT_TAB_OPTIONS.APPLICABLE,
  },
  {
    label: EVIDENCE_REQUIREMENT_TAB_OPTIONS.NOT_APPLICABLE,
    value: EVIDENCE_REQUIREMENT_TAB_OPTIONS.NOT_APPLICABLE,
  },
];

export const NO_PRODUCT_GROUPS_FOUND = "No Product Groups Found";

export const NO_PRODUCT_GROUPS_FOUND_OPTION = {
  value: 0,
  label: NO_PRODUCT_GROUPS_FOUND,
  disabled: true
}

export const GUARDRAIL_CONFIRMATION_NEW_ASSOCIATION =
  'You are currently associated to [CLIENT/VENDOR] CURRENT_ASSOCIATION. Proceeding will remove the association from CURRENT_ASSOCIATION, and add an association to NEW_ASSOCIATION.';
export const GUARDRAIL_CONFIRMATION_FIRST_LAUNCH =
  'This action shall launch the portal for [CLIENT/VENDOR] NEW_ASSOCIATION. Proceeding will associate you with NEW_ASSOCIATION.';
export const GUARDRAIL_CONFIRMATION_SAME_ASSOCIATION_LAUNCH = 'This action shall launch the portal for [CLIENT/VENDOR] NEW_ASSOCIATION.';

export const VENDOR_APP_ID = 2;
export const VENDOR_USER_ROLE_ID = 2001;
export const ALWAYS_DATA_ACCESS_OPTION = 1;
export const NO_VENDOR_CLEARED_ASSESSMENTS = 0;

export const OVERVIEW = 'overview';
export const EVIDENCE_REVIEW = 'evidence-review';
export const SUMMARY = 'summary';
export const RESULTS_PREVIEW = 'results-preview';

export const PRODUCT_DETAILS_SIDEBAR_MENUS = [
  { label: 'OVERVIEW', value: OVERVIEW, faIcon: faGlobe },
  { label: 'EVIDENCE REVIEW', value: EVIDENCE_REVIEW, faIcon: faShield },
  { label: 'SUMMARY', value: SUMMARY, faIcon: faScrewdriverWrench },
  { label: 'RESULTS PREVIEW', value: RESULTS_PREVIEW, faIcon: faCheck },
];

export const CANCEL_ASSESSMENT_SUMMARY_MESSAGE = 'You opted for cancelling the changes made so far. Do you want to proceed?';

//Product Preferences Wizard Constants
export const DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX = 0;
export const PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE = 1;
export const PRODUCT_PREFERENCES_COMPLETED_STATUS_ID = 3;
export const PRODUCT_PREFERENCES_DRAFT_STATUS_ID = 1;
export const PREFERENCES_SAVED_SUCCESSFULLY = 'Preferences Saved Successfully';
export const SAVE_AND_CLOSE_TOOLTIP_MESSAGE = "Save all responses and close the wizard.";
export const COMPLETE_TOOLTIP_MESSAGE = "Save all responses and mark the wizard as completed.";
export const UNSAVED_PRODUCT_PREFERENCES_QUESTIONS_MESSAGE = "There are unsaved changes. Do you wish to save?";
export const PRODUCT_PREFERENCES_SET_SUCCESSFULLY = "Product Preferences set successfully";
export const PRODUCT_PREFERENCES_WIZARD_TYPE_ID = 1;

export const PRODUCT_PROFILE_WIZARD_TAB_OPTIONS = {
  PRODUCT_CATALOG_PREFERENCES: 'PRODUCT CATALOG PREFERENCES',
  PRODUCT_INFORMATION: 'PRODUCT INFORMATION',
};

//Product Information Wizard Constants
export const PRODUCT_CATALOG_WIZARD_QUESTION_TYPE = 1;
export const PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE = 2;
export const SINGLE_SELECT_DROPDOWN_RESPONSE_ID = 9;
export const DROPDOWN_LOOKUP_RESPONSE_ID = 11;
export const PRODUCT_INFORMATION_COMPLETED_STATUS_ID = 3;
export const PRODUCT_INFORMATION_WIZARD_TYPE_ID = 2;
export const DEFAULT_PRODUCT_INFORMATION_QUESTION_INDEX = 0;
export const PRODUCT_INFORMATION_UNSAVED_CHANGES_MESSAGE = "Leaving this page may result in lost progress. Are you sure you want to navigate away?";
export const IS_REQUIRED = 1;
export const PRODUCT_ALREADY_EXISTS = "Association of the Product 'XXXXX' already exists with this Vendor."
export const ASSESSMENT_TYPE_CORL_CLEARED = 2;

export const PRODUCT_RELATED_ASSESSMENT_INITIATED_TOOLTIP_MESSAGE = "CORLCleared Assessment for this Product has started, hence the Product cannot be deleted."

export const CORL_AUDITOR_GRADING = 24;

export const SOMETIMES_VALUE_ID = 2;

export const ASSESSMENT_TYPE_CLIENT_INITIATED = "Client Initiated";

export const NULL_INADEQUATRE_REASON_REQUIREMENT_ERROR_MESSAGE = 'Every Inadequate Requirement Outcome must have associated Inadequacy Reason to proceed forward';

export const AUDITOR_CONFIRMED_REQUIREMENT = 1;
export const AUDITOR_NON_CONFIRMED_REQUIREMENT = 0;

export const AUDIT_REVIEW_REQUIRED_REQUIREMENT = 1;